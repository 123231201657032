import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { Box, Heading, Container, Text, Button, Stack } from "@chakra-ui/react";

const NotFoundPage = () => {
  return (
    <Container maxW={"3xl"}>
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 8, md: 14 }}
        py={{ base: 20, md: 36 }}
      >
        <Heading fontWeight={600}>
          Uh oh... <br />
          <Text as={"span"} color={"green.500"} fontSize="18px">
            that page doesn't exist!
          </Text>
        </Heading>

        <Stack
          direction={"column"}
          align={"center"}
          alignSelf={"center"}
          position={"relative"}
        >
          <Button
            as={GatsbyLink}
            to="/"
            colorScheme={"green"}
            bg={"green.300"}
            px={6}
            color="white"
            _hover={{
              bg: "green.500",
            }}
          >
            Home
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

export default NotFoundPage;
